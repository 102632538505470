<template>
  <div>
    <b-card>
      <div class="custom-search d-flex justify-content-between">
        <div class="d-flex">
          <div>
            <b-button to="/discounts/add" variant="primary">
              Add Discount</b-button
            >
          </div>
          <div v-if="itemsToTakeAction.length > 0" class="ml-1">
            <b-button @click="deleteDiscounts" variant="danger"
              >Delete Selected</b-button
            >
          </div>
        </div>
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="discounts"
        :isLoading="isDataLoading"
        @on-select-all="onSelectAll"
        @on-row-click="onRowClick"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Coupon name -->
          <span v-if="props.column.field === 'name'">
            <router-link :to="`/discounts/update/${props.row._id}`">
              {{ props.row.name }}
            </router-link>
          </span>

          <!-- Column: Coupon code -->
          <span v-else-if="props.column.field === 'couponCode'">
            {{ props.row.couponCode }}
          </span>

          <!-- Column: Valid days -->
          <span v-else-if="props.column.field === 'validDays'">
            <b-badge
              :class="index === 0 ? 'ml-0' : 'ml-1'"
              v-for="(day, index) in props.row.validDays"
              :key="index"
              variant="light-primary"
            >
              {{ day }}
            </b-badge>
          </span>
          <!-- Column: is Hidden -->
          <span v-else-if="props.column.field === 'isHide'">
            <b-badge v-if="props.row.isHide" variant="light-success">
              {{ "YES" }}
            </b-badge>
            <b-badge v-else variant="light-secondary">
              {{ "NO" }}
            </b-badge>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10', '15']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { getUserToken } from "@/auth/utils";
import axios from "axios";

export default {
  components: {
    VueGoodTable,

    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
  },
  data() {
    return {
      discounts: [],
      itemsToTakeAction: [],
      isDataLoading: true,
      pageLength: 15,
      columns: [
        {
          label: "Discount",
          field: "name",
        },
        {
          label: "Code",
          field: "couponCode",
        },
        {
          label: "Valid Days",
          field: "validDays",
        },
        {
          label: "isHidden",
          field: "isHide",
        },
      ],
      searchTerm: "",
    };
  },
  methods: {
    getDiscounts() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/hotel/discounts/get-lists`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.discounts = response.data;
          this.isDataLoading = false;
        })
        .catch((error) => {
          this.isDataLoading = false;
          console.log(error.response);
        });
    },

    deleteDiscounts(props) {
      if (props._id) {
        this.itemsToTakeAction.push(props._id);
      }
      if (confirm("Are you sure want to delete the selected Discounts?")) {
        this.isDataLoading = true;
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/hotel/discounts/delete`,
            {
              discountIDs: this.itemsToTakeAction,
            },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            }
          )
          .then((response) => {
            for (let i = 0; i < this.itemsToTakeAction.length; i++) {
              const item = this.itemsToTakeAction[i];
              const index = this.discounts.findIndex((discount) => {
                return discount._id == item;
              });
              this.discounts.splice(index, 1);
            }
            this.itemsToTakeAction = [];
            this.isDataLoading = false;
          })
          .catch((error) => {
            console.log(error);
            this.isDataLoading = false;
          });
      }
    },
    onSelectAll(params) {
      const selectedItems = params.selectedRows.map((item) => {
        return item._id;
      });
      this.itemsToTakeAction = selectedItems;
    },
    onRowClick(params) {
      const item = params.row._id;
      if (!params.selected) {
        const itemIndex = this.itemsToTakeAction.findIndex((itemObj) => {
          return itemObj == item;
        });
        if (itemIndex > -1) {
          this.itemsToTakeAction.splice(itemIndex, 1);
        }
      } else {
        this.itemsToTakeAction.push(item);
      }
    },
  },
  created() {
    this.getDiscounts();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
